<template>
  <div class="assign-unit-banker fd-validation" :class="{ error: hasError }">
    <fd-form-section title="Assign Bankers">
      <banner
        v-if="hasError"
        class="col-12 bg-danger fg-white py-2"
        :dismissable="false"
      >
        Please assign at least 1 banker to this project.
      </banner>

      <!-- To Assign List -->
      <BankersToAssign
        class="col-12 md-col-6 px-3"
        :bankers="bankersAvailable"
        :isLoading="isBankerToAssignLoading"
        @search="searchBankerToAssign"
        @assign="assignBanker"
        @infinite-scroll="loadNextBankersToAssign"
      ></BankersToAssign>

      <!-- Assigned List -->
      <BankersAssigned
        class="col-12 md-col-6 px-3"
        :bankers="bankersAssigned"
        :isLoading="isBankersAssignedLoading"
        @remove="removeAssignedBanker"
        @infinite-scroll="loadNextBankersAssigned"
      >
      </BankersAssigned>
    </fd-form-section>
  </div>
</template>

<script>
import useValidator from "@/modules/General/composables/useValidator";
import useAssignToUnit from "@/modules/Project/composables/useAssignToUnit";
import differenceBy from "lodash/differenceBy";

import ManagerRoles from "@/modules/Project/classes/ManagerRoles";
import ProjectUnitAPI from "@/modules/Project/api/projectUnit";

export default {
  setup(props, context) {
    const { validate, hasError, errorMessage } = useValidator(props);
    const {
      toAssign: bankersToAssign,
      isToAssignLoading: isBankerToAssignLoading,
      searchToAssign: searchBankerToAssign,
      loadToAssign: loadBankersToAssign,
      loadNextToAssign: loadNextBankersToAssign,
      resetToAssignState: resetToAssignBankersState
    } = useAssignToUnit("banker", { props, context });

    return {
      // Validator
      validate,
      hasError,
      errorMessage,
      // Assign To Unit
      bankersToAssign,
      isBankerToAssignLoading,
      searchBankerToAssign,
      loadBankersToAssign,
      loadNextBankersToAssign,
      resetToAssignBankersState
    };
  },
  components: {
    Banner: () => import("@/components/GlobalComponents/Banner"),
    BankersToAssign: () => import("./BankersToAssign"),
    BankersAssigned: () => import("./BankersAssigned")
  },
  mixins: [],
  props: {
    value: {
      type: Array,
      default: () => []
    },
    isEdit: {
      type: Boolean,
      default: false
    },
    required: {
      type: Boolean,
      default: false
    },
    projectId: {
      type: [Number, String]
    },
    projectUnitId: {
      type: [Number, String]
    },
    type: {
      type: String,
      validator: (val) => new ManagerRoles().getRoles().includes(val)
    },
    mapValue: {
      type: Function,
      default: (val) => val
    }
  },
  data: function () {
    return {
      projectUnitAPI: new ProjectUnitAPI(this.type),

      isBankersAssignedLoading: false,
      bankersAssigned: [],
      bankersAssignedPagination: {
        page: 1,
        total: 1
      }
    };
  },
  computed: {
    bankersAvailable() {
      let bankers = differenceBy(
        this.bankersToAssign,
        this.bankersAssigned,
        (b) => b.id
      );
      return bankers;
    }
  },
  watch: {},
  created: function () {},
  beforeDestroy: function () {},
  mounted: function () {
    this.init();
  },
  methods: {
    async init() {
      if (this.isEdit) {
        Promise.all([this.loadBankersToAssign(), this.loadBankersAssigned()]);
      } else {
        await this.loadBankersToAssign();
        this.assignAllBankers();
      }
    },
    assignBanker(user) {
      this.bankersAssigned.push(user);
      this.emitValue();
    },
    assignAllBankers() {
      this.bankersToAssign.forEach((banker) => {
        this.bankersAssigned.push(banker);
      });
      this.emitValue();
    },

    emitValue() {
      this.$emit(
        "input",
        this.bankersAssigned.map((user) => this.mapValue(user))
      );
      this.$emit(
        "update",
        this.bankersAssigned
          .filter((user) => !user.existing)
          .map((user) => this.mapValue(user))
      );
    },

    async removeAssignedBanker(user) {
      if (user.existing) {
        await this.removeExistingBanker(user);
      } else {
        this.removeBanker(user);
      }
    },

    removeBanker(user) {
      this.bankersAssigned = this.bankersAssigned.filter(
        (b) => b.id != user.id
      );
      this.emitValue();
    },

    async removeExistingBanker(user) {
      let confirm = await this.$confirm({
        type: "alert",
        title: `Remove Allocated Banker`,
        message: `Are you sure you want to remove ${user.name} from the banker allocation?`,
        confirmText: "Remove"
      });

      if (confirm) {
        try {
          await this.projectUnitAPI.removeAssignedBanker(this.projectUnitId, {
            bankerId: user.id
          });
          this.removeBanker(user);
          this.$notify({
            group: "alert",
            type: "success",
            title: "Success",
            text: `${user.name} has been removed from the banker allocation successfully.`
          });

          this.resetToAssignBankersState();
          await this.loadBankersToAssign();
        } catch (error) {
          this.$notify({
            group: "alert",
            type: "error",
            title: "Error",
            text: `Failed to remove banker. Please try again later.`
          });
        }
      }
    },
    async loadBankersAssigned(search = "") {
      const perPage = 15;

      let params = {
        queries: {
          "name[partial]": search
        },
        page: this.bankersAssignedPagination.page,
        perPage: perPage
      };

      try {
        this.isBankersAssignedLoading = true;
        let res = await this.projectUnitAPI.getAssignedBankers(
          this.projectUnitId,
          params
        );

        this.setBankersAssignedData(res);

        this.isBankersAssignedLoading = false;
      } catch (error) {
        this.isBankersAssignedLoading = false;
        this.$notify({
          group: "alert",
          type: "error",
          title: "Error",
          text: `Failed to fetch assigned bankers. Please try again later.`
        });
      }
    },
    setBankersAssignedData(res) {
      // Append data to current user list
      this.bankersAssigned = [
        ...this.bankersAssigned,
        ...this._.cloneDeep(res.data).map((user) => ({
          ...user,
          existing: true
        }))
      ];
      // Emit value
      this.emitValue();
      // Update total page
      this.bankersAssignedPagination.total = res.meta.pagination.total_pages;
    },
    async loadNextBankersAssigned(scrollState) {
      let page = this.bankersAssignedPagination.page;
      let total = this.bankersAssignedPagination.total;
      // Load more if there are more pages
      if (page < total) {
        this.bankersAssignedPagination.page++;
        await this.load();
        scrollState.loaded();
      } else {
        scrollState.loaded();
        scrollState.complete();
      }
    },

    resetBankersAssignedState() {
      this.bankersAssigned = [];
      Object.assign(this.bankersAssignedPagination, {
        page: 1,
        total: 1
      });
    },

    async reloadBankersAssigned() {
      this.resetBankersAssignedState();
      await this.loadBankersAssigned();
    }
  }
};
</script>

<style lang="scss"></style>
